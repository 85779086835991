<template>
  <ClientOnly>
  <Teleport to="body">
    <TsModalTrolleyItemConfirmation
      append-class="overflow-y-auto"
      v-model:visible="productStore.is_confirmation_modal_visible"
      data-testid="trolley-add-ons-recommendations"
      :product="productStore.product"
      :quantity="productStore.quantity_to_add"
      :method="productStore.delivery_method"
      :branch="
        branchStore.lastSavedBranch && branchStore.lastSavedBranch.name
          ? branchStore.lastSavedBranch.name
          : branchStore.defaultBranch && branchStore.defaultBranch.name
          ? branchStore.defaultBranch.name
          : null
      "
      @go-to-trolley="handleTrolleyPageNavigation"
      @hide="productStore.is_confirmation_modal_visible = false"
    />
  </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
const productStore = useProductStore();

const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();

// watch for a single product
watch(
  () => [branchStore.is_branch_set, trolleyStore.previewed_product],
  async () => {
    if (!branchStore.is_branch_set || !trolleyStore.previewed_product) return;

    trolleyStore.collection_stock_loading = true;

    trolleyStore.collection_availability = await trolleyStore.evaluateCollectionAvailability(
      trolleyStore.previewed_product
    );

    trolleyStore.collection_stock_loading = false;
  }
);

async function handleTrolleyPageNavigation() {
  trolleyStore.confirmation_overlay_visible = false;
  await navigateTo("/trolley");
}

onBeforeRouteLeave(() => {
  trolleyStore.confirmation_overlay_visible = false;
});

// necessary to avoid any stale modal opening after a lot of subsequent trolley additions
onUnmounted(() => {
  trolleyStore.confirmation_overlay_visible = false;
});
</script>
